import invert from "invert-color"
import { defineStore } from "pinia"

const hexValues = {
    "95": "F2",
    "90": "E6",
    "85": "D9",
    "80": "CC",
    "75": "BF",
    "70": "B3",
    "65": "A6",
    "60": "99",
    "55": "8C",
    "50": "80",
    "45": "73",
    "40": "66",
    "35": "59",
    "30": "4D",
    "25": "40",
    "20": "33",
    "15": "26",
    "10": "1A",
    "5": "0D",
}

const hexMap = new Map()

for (const [key, value] of Object.entries(hexValues)) {
    hexMap.set(key, value)
}

const gfPrimary = "#082250"
const gfSecondary = "#F9CB32"
const gfDark = "#1A1A1A"
const gfLight = "#fafafa"

export const useColorsStore = defineStore("colors", {
    state: () => ({
        primary: gfPrimary,
        secondary: gfSecondary,
        dark: gfDark,
        light: gfLight,
    }),

    getters: {
        primaryBW(state) {
            invert(state.primary, {
                black: state.dark,
                white: state.light,
            })
        },
        primaryBWOpacity10(state) {
            return state.primary
        },
        gfPrimary() {
            return gfPrimary
        },
        gfSecondary() {
            return gfSecondary
        },
    },

    actions: {
        setCSSVars() {
            const root = document.documentElement

            const colorInvertBWHex = invert(this.primary, {
                black: gfDark,
                white: gfLight,
            })

            const colorInvertBWHexSecondary = invert(this.secondary, {
                black: gfDark,
                white: gfLight,
            })

            root.style.setProperty("--dark", this.dark)
            root.style.setProperty("--light", this.light)
            root.style.setProperty("--primary-color", this.primary)
            root.style.setProperty("--secondary-color", this.secondary)
            root.style.setProperty("--primary-color-bw", colorInvertBWHex)
            root.style.setProperty(
                "--secondary-color-bw",
                colorInvertBWHexSecondary
            )

            root.style.setProperty("--gf-primary", this.gfPrimary)
            root.style.setProperty("--gf-secondary", this.gfSecondary)

            // Dark opacity vars
            hexMap.forEach((hexVal, percent) => {
                const hex = this.dark + hexVal
                root.style.setProperty("--dark-" + percent, hex)
            })

            // Primary opacity vars
            hexMap.forEach((hexVal, percent) => {
                const hex = this.primary + hexVal

                root.style.setProperty(
                    "--primary-color-opacity-" + percent,
                    hex
                )
            })

            // Secondary opacity vars
            hexMap.forEach((hexVal, percent) => {
                const hex = this.secondary + hexVal

                root.style.setProperty(
                    "--secondary-color-opacity-" + percent,
                    hex
                )
            })

            // Primary black and white opacity vars
            hexMap.forEach((hexVal, percent) => {
                const hex = "#" + colorInvertBWHex.slice(1) + hexVal

                root.style.setProperty(
                    "--primary-color-bw-opacity-" + percent,
                    hex
                )
            })
        },
    },
})
